import React, { useRef, useEffect, useState } from "react";
import "../style/UserPreferencesModal.css";
import { saveUserPreferences, getUserPreferences } from "../api.js";
import { useSelector, useDispatch } from "react-redux";
import { updateModal } from "../store/project.js";

function UserPreferencesModal() {
    const modalRef = useRef(null);
    const [editMode, setEditMode] = useState({});
    const [userPrefs, setUserPrefs] = useState(null);
    const [preferencesLoaded, setPreferencesLoaded] = useState(false);
    const { modal } = useSelector((state) => state.project);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.data);
    const token = useSelector(state => state.user.access_token);

    // Determine if the modal is open based on Redux state

    useEffect(() => {
        const fetchUserPreferences = async () => {
            if (!preferencesLoaded && user) {
                try {
                    const preferences = await getUserPreferences(user.user_id, token);
                    setUserPrefs(preferences);
                    setPreferencesLoaded(true);
                } catch (err) {
                    console.error("Error fetching user preferences:", err);
                }
            }
        };

        fetchUserPreferences();
    }, [preferencesLoaded, user]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                modalRef.current &&
                !modalRef.current.contains(event.target)
            ) {
                dispatch(updateModal({ name: '', data: null }));
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dispatch]);

    const toggleEdit = (field) => {
        setEditMode((prev) => ({ ...prev, [field]: !prev[field] }));
    };

    const handleInputChange = (field, value) => {
        setUserPrefs((prev) => ({ ...prev, [field]: value }));
    };

    const handleSave = async (field, value) => {
        try {
            const updatedPrefs = { ...userPrefs, [field]: value };
            const savedPrefs = await saveUserPreferences(updatedPrefs, token);
            setUserPrefs(savedPrefs);
            setEditMode((prev) => ({ ...prev, [field]: false }));
        } catch (error) {
            console.error("Failed to save user preferences:", error);
            // Optionally, you can add error handling UI here
        }
    };

    const renderField = (field, label, isLarge = false) => {
        const value = userPrefs[field];
        const displayValue =
            typeof value === "object" ? JSON.stringify(value, null, 2) : value;
        const truncatedValue =
            typeof displayValue === "string"
                ? displayValue.split("\n").slice(0, 2).join("\n")
                : displayValue;
        return (
            <div className="up-field">
                <label>{label}:</label>
                {editMode[field] ? (
                    isLarge ? (
                        <textarea
                            value={displayValue}
                            onChange={(e) => handleInputChange(field, e.target.value)}
                            onBlur={(e) => handleSave(field, e.target.value)}
                            onKeyPress={(e) => {
                                if (e.key === "Enter" && !e.shiftKey) {
                                    e.preventDefault();
                                    handleSave(field, e.target.value);
                                }
                            }}
                            className="up-textarea"
                        />
                    ) : (
                        <input
                            type="text"
                            value={displayValue}
                            onChange={(e) => handleInputChange(field, e.target.value)}
                            onBlur={(e) => handleSave(field, e.target.value)}
                            onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                    e.preventDefault();
                                    handleSave(field, e.target.value);
                                }
                            }}
                            className="up-input"
                        />
                    )
                ) : (
                    <span>{truncatedValue}</span>
                )}
                <button onClick={() => toggleEdit(field)} className="up-edit-button">
                    <img src="/images/pencil.svg" alt="Edit" className="up-edit-icon" />
                </button>
            </div>
        );
    };

    if (!user) {
        return (
            <div className="up-modal-overlay">
                <div className="up-modal-content" ref={modalRef}>
                    <h2>Loading user data...</h2>
                </div>
            </div>
        );
    }

    return (
        <div className="up-modal-overlay">
            <div className="up-modal-content" ref={modalRef}>
                <h2>User Preferences</h2>
                <div className="up-fields-container">
                    {userPrefs && (
                        <>
                            {renderField("name", "Name")}
                            {renderField("company_institution", "Company/Institution")}
                            {renderField("lab", "Lab")}
                            {renderField("cloning_preferences", "Cloning Preferences", true)}
                            {renderField("equipment_external", "Equipment", true)}
                            {renderField("vendors_external", "Vendors", true)}
                            {renderField("lab_notebook_template", "Lab Notebook Template")}
                            {renderField("file_types_external", "File Types", true)}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default UserPreferencesModal;