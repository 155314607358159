import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.js";
import "./index.css";
import { Provider } from "react-redux";
import store from "./store/index.js";
import { Auth0Provider } from "@auth0/auth0-react";

ReactDOM.createRoot(document.getElementById("root")).render(
    <Auth0Provider
        // domain="dev-07aa130v3f75zx4h.us.auth0.com"
        domain="login.labkick.ai"
        clientId="wIR2ovSzlTWy30MYdyeKmsHsFnjcMMds"
        authorizationParams={{
            audience: "http://localhost:5000",
            redirect_uri: window.location.origin,
        }}
    >
        <Provider store={store}>
            <App />
        </Provider>
    </Auth0Provider>
);
