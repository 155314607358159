import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import { BiSend } from "react-icons/bi";
import "../style/ChatInput.css";
import DOMPurify from "dompurify";
import { useSelector, useDispatch } from "react-redux";
import {
  sendChatMessage,
  updateModal,
  setIsLoading,
  setError,
  clearSendMessageError,
  updateArtifact,
} from "../store/project.js";

function ChatInput() {
  const [inputHtml, setInputHtml] = useState("");
  const [inputHeight, setInputHeight] = useState("auto");
  const [showTooltip, setShowTooltip] = useState(false);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [visibleStartIndex, setVisibleStartIndex] = useState(0);
  const [caretPosition, setCaretPosition] = useState({ x: 0, y: 0 });
  const [lastAtSymbolIndex, setLastAtSymbolIndex] = useState(-1);
  const inputRef = useRef(null);
  const tooltipRef = useRef(null);
  const cursorPositionRef = useRef(0);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.data);
  const currentProjectId = useSelector(
    (state) => state.project.currentProjectId
  );
  const currentProject = useSelector((state) => state.project.currentProject);
  const isResponseLoading = useSelector((state) => state.project.isLoading);
  const sendMessageError = useSelector(
    (state) => state.project.sendMessageError
  );

  useEffect(() => {
    // Ensure the selected item is always visible in the tooltip
    if (selectedIndex < visibleStartIndex) {
      setVisibleStartIndex(selectedIndex);
    } else if (selectedIndex >= visibleStartIndex + 4) {
      setVisibleStartIndex(selectedIndex - 3);
    }
  }, [selectedIndex]);

  useEffect(() => {
    adjustInputHeight();
  }, [inputHtml]);

  const handleSendMessage = async (message) => {
    try {
      if (!currentProjectId) {
        throw new Error(
          "No project selected. Please select a project before sending a message."
        );
      }

      dispatch(
        sendChatMessage({
          message,
          chatId: currentProject.chat_id,
          projectId: currentProjectId,
          currentProject,
        })
      );

      // Clear the input immediately after sending
      setInputHtml("");
      if (inputRef.current) {
        inputRef.current.innerHTML = ""; // Clear the contentEditable div
      }
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const adjustInputHeight = () => {
    if (inputRef.current) {
      // Temporarily set height to auto to get the correct scrollHeight
      inputRef.current.style.height = "auto";

      const scrollHeight = inputRef.current.scrollHeight;
      const minHeight = 48; // 3rem in pixels, adjust if needed
      const maxHeight = window.innerHeight * 0.4; // 40% of viewport height

      const newHeight = Math.min(Math.max(scrollHeight, minHeight), maxHeight);

      inputRef.current.style.height = `${newHeight}px`;
      setInputHeight(`${newHeight}px`);
    }
  };

  const updateCaretPosition = () => {
    if (inputRef.current) {
      const selection = window.getSelection();
      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const clonedRange = range.cloneRange();
        clonedRange.selectNodeContents(inputRef.current);
        clonedRange.setEnd(range.endContainer, range.endOffset);

        const rects = clonedRange.getClientRects();
        if (rects.length > 0) {
          const lastRect = rects[rects.length - 1];
          const inputRect = inputRef.current.getBoundingClientRect();

          setCaretPosition({
            x: lastRect.right - inputRect.left,
            y: lastRect.top - inputRect.top,
          });
        }
      }
    }
  };

  useEffect(() => {
    if (tooltipRef.current && inputRef.current) {
      const inputRect = inputRef.current.getBoundingClientRect();
      tooltipRef.current.style.left = `${inputRect.left + caretPosition.x}px`;
    }
  }, [caretPosition]);

  useLayoutEffect(() => {
    if (inputRef.current) {
      restoreCursorPosition(cursorPositionRef.current);
    }
  }, [inputHtml]);

  const saveCursorPosition = () => {
    if (inputRef.current) {
      const selection = window.getSelection();
      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const preCaretRange = range.cloneRange();
        preCaretRange.selectNodeContents(inputRef.current);
        preCaretRange.setEnd(range.endContainer, range.endOffset);
        cursorPositionRef.current = preCaretRange.toString().length;
      }
    }
  };

  const restoreCursorPosition = (cursorPosition) => {
    if (inputRef.current) {
      const range = document.createRange();
      const sel = window.getSelection();
      range.setStart(inputRef.current, 0);
      range.collapse(true);
      let nodeStack = [inputRef.current],
        node,
        foundStart = false,
        stop = false;
      let charCount = 0;

      while (!stop && (node = nodeStack.pop())) {
        if (node.nodeType === Node.TEXT_NODE) {
          const nextCharCount = charCount + node.length;
          if (
            !foundStart &&
            cursorPosition >= charCount &&
            cursorPosition <= nextCharCount
          ) {
            range.setStart(node, cursorPosition - charCount);
            foundStart = true;
          }
          if (
            foundStart &&
            cursorPosition >= charCount &&
            cursorPosition <= nextCharCount
          ) {
            range.setEnd(node, cursorPosition - charCount);
            stop = true;
          }
          charCount = nextCharCount;
        } else {
          let i = node.childNodes.length;
          while (i--) {
            nodeStack.push(node.childNodes[i]);
          }
        }
      }

      sel.removeAllRanges();
      sel.addRange(range);
    }
  };

  const handleInputChange = (e) => {
    saveCursorPosition();

    // Convert pasted HTML content to plain text
    if (e.type === "paste") {
      e.preventDefault();
      const text = e.clipboardData.getData("text/plain");
      document.execCommand("insertText", false, text);
    }

    // Update the input HTML
    const newHtml = e.currentTarget.innerHTML;
    setInputHtml(newHtml);

    updateCaretPosition();
    adjustInputHeight();

    const textContent = e.currentTarget.textContent;
    const newLastAtSymbolIndex = textContent.lastIndexOf("@");

    if (newLastAtSymbolIndex !== -1) {
      setLastAtSymbolIndex(newLastAtSymbolIndex);
      const searchTerm = textContent.slice(newLastAtSymbolIndex + 1);

      let filtered;
      if (searchTerm === "") {
        filtered = user?.dna_files ? Object.values(user.dna_files) : [];
      } else {
        filtered = user?.dna_files
          ? Object.values(user.dna_files)
          : [];
      }

      // Apply the new filtering logic
      filtered = filtered.filter(file => {
        if (file.file_type !== "DNA") return false;
        if (file.part_source === "digest") {
          return file.project_ids.includes(currentProjectId);
        }
        return file.part_source !== "digest";
      });

      // Then apply the name filter
      filtered = filtered.filter(file =>
        file.file_name.toLowerCase().includes(searchTerm.toLowerCase())
      );

      setFilteredFiles(filtered);
      setShowTooltip(true);

      // Set selectedIndex to the last file
      const lastIndex = filtered.length - 1;
      setSelectedIndex(lastIndex);

      // Set visibleStartIndex to show the last four files
      const visibleStart = Math.max(0, filtered.length - 4);
      setVisibleStartIndex(visibleStart);
    } else if (lastAtSymbolIndex !== -1) {
      // If there was a previous '@' but it's gone now, hide the tooltip
      setShowTooltip(false);
      setFilteredFiles([]);
      setLastAtSymbolIndex(-1);
    }
  };

  // Helper function to get cursor position
  const getCursorPosition = (element) => {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const preCaretRange = range.cloneRange();
    preCaretRange.selectNodeContents(element);
    preCaretRange.setEnd(range.endContainer, range.endOffset);
    return preCaretRange.toString().length;
  };

  // Helper function to set cursor position
  const setCursorPosition = (element, position) => {
    const range = document.createRange();
    const sel = window.getSelection();

    let currentNode = element.firstChild;
    let currentOffset = 0;

    while (currentNode) {
      if (currentNode.nodeType === Node.TEXT_NODE) {
        const nodeLength = currentNode.length;
        if (currentOffset + nodeLength >= position) {
          range.setStart(currentNode, position - currentOffset);
          range.collapse(true);
          sel.removeAllRanges();
          sel.addRange(range);
          return;
        }
        currentOffset += nodeLength;
      } else if (currentNode.nodeType === Node.ELEMENT_NODE) {
        const nodeLength = currentNode.textContent.length;
        if (currentOffset + nodeLength >= position) {
          if (currentNode.childNodes.length > 0) {
            currentNode = currentNode.firstChild;
            continue;
          } else {
            range.setStartAfter(currentNode);
            range.collapse(true);
            sel.removeAllRanges();
            sel.addRange(range);
            return;
          }
        }
        currentOffset += nodeLength;
      }
      currentNode = currentNode.nextSibling;
    }

    // If we've gone through all nodes and haven't found the position,
    // set the cursor at the end of the content
    range.selectNodeContents(element);
    range.collapse(false);
    sel.removeAllRanges();
    sel.addRange(range);
  };

  const measureTextWidth = (text) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = getComputedStyle(inputRef.current).font;
    return context.measureText(text).width;
  };

  const handleFileSelect = (file) => {
    if (file) {
      const cursorPosition = getCursorPosition(inputRef.current);
      const textBeforeCursor = inputRef.current.textContent.slice(
        0,
        cursorPosition
      );
      const lastAtSymbolIndex = textBeforeCursor.lastIndexOf("@");

      // Create a temporary div to parse the HTML
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = inputHtml;

      // Insert the new file tag
      const newSpan = document.createElement("span");
      newSpan.className = "file-tag";
      newSpan.textContent = file.file_name;
      // Add the file ID as a data attribute
      newSpan.dataset.fileId = file.dna_id;

      // Find the correct insertion point
      let currentNode = tempDiv.firstChild;
      let currentPosition = 0;
      let insertionNode = null;
      let insertionOffset = 0;

      while (currentNode) {
        if (currentNode.nodeType === Node.TEXT_NODE) {
          if (currentPosition + currentNode.length > lastAtSymbolIndex) {
            insertionNode = currentNode;
            insertionOffset = lastAtSymbolIndex - currentPosition;
            break;
          }
          currentPosition += currentNode.length;
        } else if (currentNode.nodeType === Node.ELEMENT_NODE) {
          currentPosition += currentNode.textContent.length;
        }
        currentNode = currentNode.nextSibling;
      }

      // Insert the new span
      if (insertionNode) {
        const beforeText = insertionNode.textContent.slice(0, insertionOffset);
        const afterText = insertionNode.textContent.slice(insertionOffset);

        // Remove everything from '@' to the next space or end of string
        const afterTextCleaned = afterText.replace(/^@\S*\s?/, "");

        const beforeTextNode = document.createTextNode(beforeText);
        const afterTextNode = document.createTextNode(afterTextCleaned);

        insertionNode.parentNode.insertBefore(beforeTextNode, insertionNode);
        insertionNode.parentNode.insertBefore(newSpan, insertionNode);
        insertionNode.parentNode.insertBefore(
          document.createTextNode(" "),
          insertionNode
        );
        insertionNode.parentNode.insertBefore(afterTextNode, insertionNode);
        insertionNode.parentNode.removeChild(insertionNode);
      } else {
        tempDiv.appendChild(newSpan);
        tempDiv.appendChild(document.createTextNode(" "));
      }

      // Set the new HTML
      setInputHtml(tempDiv.innerHTML);
      setShowTooltip(false);

      // Set cursor position after the inserted tag and space
      setTimeout(() => {
        const newCursorPosition = lastAtSymbolIndex + file.file_name.length + 1; // +1 for the space
        setCursorPosition(inputRef.current, newCursorPosition);
        inputRef.current.focus();
      }, 0);
    }
  };

  const scrollToBottom = () => {
    const chatContainer = document.querySelector(".chat-messages-container");
    if (chatContainer) {
      chatContainer.scrollTo({
        top: chatContainer.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const handleKeyDown = (e) => {
    scrollToBottom();
    if (showTooltip && filteredFiles.length > 0) {
      switch (e.key) {
        case "ArrowUp":
          e.preventDefault();
          setSelectedIndex((prevIndex) => Math.max(0, prevIndex - 1));
          break;
        case "ArrowDown":
          e.preventDefault();
          setSelectedIndex((prevIndex) =>
            Math.min(filteredFiles.length - 1, prevIndex + 1)
          );
          break;
        case "Tab":
        case "Enter":
          e.preventDefault();
          handleFileSelect(filteredFiles[selectedIndex]);
          break;
        default:
          break;
      }
    } else if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }

    // Always update caret position, not just when tooltip is shown
    updateCaretPosition();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const html = inputRef.current.innerHTML;
    if (html.trim() && !isResponseLoading && currentProjectId) {
      handleSendMessage(html);
      setInputHtml("");
      if (inputRef.current) {
        inputRef.current.innerHTML = ""; // Clear the input
        inputRef.current.style.height = "auto"; // Reset height after sending
      }
      setInputHeight("auto"); // Reset height state after sending
    } else if (!currentProjectId) {
      console.log("No project selected. Cannot send message.");
    }
  };

  const handlePaperclipClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(updateModal({ name: "dna_archive_project", data: null }));
  };

  useEffect(() => {
    // Clear send message error when component unmounts
    return () => {
      dispatch(clearSendMessageError());
    };
  }, [dispatch]);

  return (
    <div className="main-bottom chat-input-container">
      {sendMessageError && (
        <div className="error-message">{sendMessageError}</div>
      )}
      <form className="form-container" onSubmit={handleSubmit}>
        <img
          src="/images/plus.svg"
          alt="Attach file"
          className="paperclip-icon"
          onClick={handlePaperclipClick}
          style={{ cursor: "pointer", zIndex: 10 }}
        />
        <div
          ref={inputRef}
          contentEditable
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(inputHtml) }}
          onInput={handleInputChange}
          onPaste={handleInputChange}
          onKeyDown={handleKeyDown}
          onKeyUp={updateCaretPosition}
          onClick={updateCaretPosition}
          placeholder={
            currentProjectId
              ? "Send a message."
              : "Select a project to start chatting."
          }
          className="chat-input"
          style={{ height: inputHeight, maxHeight: "40vh", overflowY: "auto" }}
        />
        {!isResponseLoading && currentProjectId && (
          <button type="submit" className="send-button">
            <BiSend size={20} />
          </button>
        )}
        {showTooltip && filteredFiles.length > 0 && (
          <div
            className={`tooltip-popper 
                            ${visibleStartIndex > 0 ? "has-top-hidden" : ""}
                            ${
                              visibleStartIndex + 4 < filteredFiles.length
                                ? "has-bottom-hidden"
                                : ""
                            }`}
            ref={tooltipRef}
            style={{
              position: "fixed",
              bottom: `${
                window.innerHeight -
                inputRef.current?.getBoundingClientRect().top
              }px`,
              display:
                showTooltip && filteredFiles.length > 0 ? "block" : "none",
            }}
          >
            {filteredFiles
              .slice(visibleStartIndex, visibleStartIndex + 4)
              .map((file, index) => (
                <div
                  key={file.dna_id}
                  className={`tooltip-option ${
                    visibleStartIndex + index === selectedIndex
                      ? "selected"
                      : ""
                  }`}
                  onClick={() => handleFileSelect(file)}
                >
                  {file.file_name}
                </div>
              ))}
          </div>
        )}
      </form>
      {/* <p>
        Your LabKick can make mistakes. Consider double checking outputs for
        veracity.
      </p> */}
    </div>
  );
}

export default ChatInput;
