import React, { useState, useEffect } from "react";
import Sidebar from "./components/Sidebar.js";
import ChatInput from "./components/ChatInput.js";
import ChatMessages from "./components/ChatMessages.js";
import ArtifactPanel from "./components/ArtifactPanel.js";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchUser,
  fetchCredential,
  populateSampleData,
  setPopupMessage, // Add this import
} from "./store/user.js";
import {
  fetchProjects,
  fetchChat,
  resetChat,
  updateArtifact,
  updateModal,
} from "./store/project.js";
import CreateProjectModal from "./components/CreateProjectModal.js";
import RenameProjectModal from "./components/RenameProjectModal.js";
import DeleteProjectModal from "./components/DeleteProjectModal.js";
import FileUploadModal from "./components/FileUploadModal.js";
import DeleteFileFromProjectModal from "./components/DeleteFileFromProjectModal.js";
import { BiPencil } from "react-icons/bi"; // Add this import

// TODO display DNA in artifact and make non-DNA artifacts not show

function App() {
  const dispatch = useDispatch();

  const { artifact, currentProjectId, modal, projectList } = useSelector(
    (state) => state.project
  );

  const chat = useSelector((state) => state.project.chat[currentProjectId]);

  const isSidebarVisible = useSelector(
    (state) => state.project.isSidebarVisible
  );

  const token = useSelector((state) => state.user.access_token);

  const isChatLoading = useSelector((state) => state.project.isChatLoading);

  const userId = useSelector((state) => state.user.user_id);

  const handleSampleClick = (sampleNumber) => {
    console.log("sample click userId", userId);
    dispatch(populateSampleData({ userId, sampleNumber }));
  };

  const isLoadingFiles = useSelector((state) => state.project.isLoadingFiles);
  const loadingMessage = useSelector((state) => state.project.loadingMessage);
  const isGeneratingInstructions = useSelector(
    (state) => state.project.isGeneratingInstructions
  );
  const instructionsLoadingMessage = useSelector(
    (state) => state.project.instructionsLoadingMessage
  );

  const design = useSelector((state) => state.project.design);

  console.log(
    "App rendering, modal:",
    modal,
    "isLoadingFiles:",
    isLoadingFiles,
    "loadingMessage:",
    loadingMessage
  );

  const handleDesignClick = () => {
    if (design) {
      dispatch(updateArtifact({ ...design, isDesign: true }));
    }
  };

  const popupMessage = useSelector((state) => state.user.popupMessage);

  // Add this new state to store the article ID
  const [articleId, setArticleId] = useState(null);

  useEffect(() => {
    // Check URL for article ID
    const searchParams = new URLSearchParams(window.location.search);
    const articleParam = Object.keys(Object.fromEntries(searchParams)).find(key => key.startsWith('article_'));
    
    if (articleParam) {
      const id = articleParam.split('_')[1];
      localStorage.setItem('pendingArticleId', id);
      setArticleId(id);
    } else {
      // Check localStorage for pending article ID
      const pendingId = localStorage.getItem('pendingArticleId');
      if (pendingId) {
        setArticleId(pendingId);
      }
    }
  }, []); // This effect runs once on mount

  useEffect(() => {
    if (articleId && token) {
      console.log(`Logged in user, Article ID: ${articleId}`);
      dispatch(populateSampleData({ userId, sampleNumber: 1 }));

      // Clear the pending article ID from localStorage
      localStorage.removeItem('pendingArticleId');
      
      // Reset articleId to prevent re-triggering
      if (userId) {
        setArticleId(null);
      }
    } else if (articleId) {
      console.log(`Not logged in, Article ID: ${articleId}`);
    }
  }, [articleId, token, userId, dispatch]); // This effect runs when articleId or token changes

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get("success") === "true") {
      dispatch(setPopupMessage("Payment succeeded! You will receive an email confirmation."));
      removeQueryParams();
    } else if (searchParams.get("canceled") === "true") {
      dispatch(setPopupMessage("Payment failed or was canceled. Please try again."));
      removeQueryParams();
    }
  }, [dispatch]);

  const removeQueryParams = () => {
    const url = new URL(window.location);
    url.searchParams.delete("success");
    url.searchParams.delete("canceled");
    window.history.replaceState({}, document.title, url.pathname);
  };

  return (
    <div
      className={`container ${isSidebarVisible ? "" : "sidebar-collapsed"} ${isLoadingFiles ? "loading" : ""
        }`}
    >
      <Sidebar />
      <div className="main-content-wrapper">
        <section className={`main ${artifact ? "with-artifact" : ""}`}>
          {projectList.length === 0 ? (
            <div className="empty-chat-container">
              <img
                src="/images/K-mark.svg"
                width={135}
                height={135}
                alt="Clonie"
              />
              <h1>LabKick</h1>
              <h3>
                {token
                  ? "What can I clone for you today?"
                  : articleId
                    ? "Please sign up or log in to view the Project"
                    : "Please sign up or log in to get started."}
              </h3>
              {token && (
                <div className="large-buttons-container">
                  <button
                    className="large-button"
                    onClick={() =>
                      dispatch(
                        updateModal({ name: "create_project", data: null })
                      )
                    }
                  >
                    <img
                      src="/images/K-mark.svg"
                      alt="New Project"
                      className="button-icon"
                    />
                    <span>Create New Project</span>
                  </button>
                  <button
                    className="large-button"
                    onClick={() => handleSampleClick(1)}
                  >
                    <img
                      src="/images/K-mark.svg"
                      alt="Sample 1"
                      className="button-icon"
                    />
                    <span>See Sample 1</span>
                  </button>
                  <button
                    className="large-button"
                    onClick={() => handleSampleClick(2)}
                  >
                    <img
                      src="/images/K-mark.svg"
                      alt="Sample 2"
                      className="button-icon"
                    />
                    <span>See Sample 2</span>
                  </button>
                </div>
              )}
            </div>
          ) : (
            <>
              <ChatMessages />
              <div className="input-design-container">
                <ChatInput />
                {/* <button className="design-button" onClick={handleDesignClick}>
                  <BiPencil size={20} />
                  Design
                </button> */}
              </div>
            </>
          )}
        </section>
        <ArtifactPanel onClose={() => dispatch(updateArtifact(null))} />
      </div>
      {modal.name === "create_project" && <CreateProjectModal />}
      {modal.name === "rename_project" && <RenameProjectModal />}
      {modal.name === "delete_project" && <DeleteProjectModal />}
      {(modal.name === "upload_general" || modal.name === "upload_project") && (
        <FileUploadModal />
      )}
      {modal.name === "delete_file" && (
        <DeleteFileFromProjectModal isOpen={true} />
      )}
      {isLoadingFiles && (
        <div className="loading-overlay">
          <div className="loading-message">
            <p>{loadingMessage || "Uploading files..."}</p>
          </div>
        </div>
      )}
      {isGeneratingInstructions && (
        <div className="loading-overlay">
          <div className="loading-message">
            <p>{instructionsLoadingMessage || "Generating instructions..."}</p>
          </div>
        </div>
      )}
      {popupMessage && (
        <div className="popup-message">
          <p>{popupMessage}</p>
          <button onClick={() => dispatch(setPopupMessage(null))}>×</button>
        </div>
      )}
    </div>
  );
}

export default App;
