import React, { useState, useEffect, useRef } from "react";
import { deleteExistingProject, updateModal, closeModal } from "../store/project.js";
import { useDispatch, useSelector } from "react-redux";
import "../style/Modal.css";  // Import the CSS file

function DeleteProjectModal({ isOpen }) {
    const [confirmValue, setConfirmValue] = useState("");
    const modalRef = useRef(null);
    const dispatch = useDispatch();
    const { modal } = useSelector((state) => state.project);
    const token = useSelector(state => state.user.access_token);

    const title = `Delete Project: ${modal.data?.project_name || ''}`;
    const confirmText = "delete";

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                modalRef.current &&
                !modalRef.current.contains(event.target)
            ) {
                dispatch(updateModal({ name: '', data: null }));
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dispatch]);

    useEffect(() => {
        setConfirmValue("");
    }, [isOpen]);

    const handleDeleteSubmit = async (e) => {
        e.preventDefault();
        if (confirmValue !== confirmText) {
            alert(`Please type "${confirmText}" to confirm.`);
            return;
        }
        try {
            const deletedProjectId = modal.data.project_id;
            dispatch(deleteExistingProject({ projectId: deletedProjectId }));
            dispatch(closeModal());
        } catch (error) {
            console.error("Error deleting project:", error);
        }
    };

    return (
        <div className="delete-project-modal-overlay" onClick={(e) => e.stopPropagation()}>
            <div className="delete-project-modal-content" ref={modalRef}>
                <h2 className="delete-project-modal-title">{title}</h2>
                <p>Are you sure you want to delete this project? This action cannot be undone.</p>
                <form onSubmit={handleDeleteSubmit}>
                    <input
                        type="text"
                        value={confirmValue}
                        onChange={(e) => setConfirmValue(e.target.value)}
                        placeholder={`Type "${confirmText}" to confirm`}
                        className="delete-project-input"
                    />
                    <div className="delete-project-modal-buttons">
                        <button type="button" onClick={() => dispatch(closeModal())} className="delete-project-cancel-button">
                            Cancel
                        </button>
                        <button type="submit" className="delete-project-submit-button">Delete</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default DeleteProjectModal;
