import React, { useState, useCallback, useEffect, useRef } from "react";
import "../style/ArtifactPanel.css";
import { SeqViz } from "seqviz";
import { useSelector, useDispatch } from "react-redux";
import * as d3 from "d3";
import {
  generateProjectInstructions,
  clearGenerateInstructionsError,
  updateArtifact,
} from "../store/project.js";
import { fetchArtifactData } from "../store/project.js";
import { BiFullscreen, BiExitFullscreen } from "react-icons/bi";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { scaleOrdinal } from "d3-scale";
import { schemeCategory10 } from "d3-scale-chromatic";
import { FaMagic } from "react-icons/fa";
import { MdOutlineHorizontalRule } from "react-icons/md";
import { TbCircleDashed } from "react-icons/tb";
import { navigateDesign } from "../store/project.js";
import { submitArtifactInputs } from "../store/project.js";

function ArtifactPanel({ onClose }) {
  const artifact = useSelector((state) => state.project.artifact);
  const parsedArtifact = useSelector((state) => state.project.parsedSequence);
  const projectId = useSelector((state) => state.project.currentProjectId);
  const isSidebarVisible = useSelector(
    (state) => state.project.isSidebarVisible
  );
  const [width, setWidth] = useState(61);
  const [isDragging, setIsDragging] = useState(false);
  const [tooltip, setTooltip] = useState({
    visible: false,
    text: "",
    x: 0,
    y: 0,
  });

  const design = useSelector((state) => state.project.design);
  const svgRef = useRef(null);
  const svgContainerRef = useRef(null);
  const [viewerType, setViewerType] = useState("linear");
  const [selectedSequence, setSelectedSequence] = useState(null);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.project.isLoading);
  const instructions = useSelector((state) => state.project.instructions);
  const isGeneratingInstructions = useSelector(
    (state) => state.project.isGeneratingInstructions
  );
  const instructionsLoadingMessage = useSelector(
    (state) => state.project.instructionsLoadingMessage
  );
  const generateInstructionsError = useSelector(
    (state) => state.project.generateInstructionsError
  );
  const [messagesWidth, setMessagesWidth] = useState(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const panelRef = useRef(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false); // State to control popup visibility
  const [markdownContent, setMarkdownContent] = useState("");
  const [editableFields, setEditableFields] = useState({});
  const [dropdownOptions, setDropdownOptions] = useState({});

  const handleInputChange = (id, value, type = 'text') => {
    if (type === 'checkbox') {
      value = !editableFields[id];
    }
    setEditableFields(prev => ({ ...prev, [id]: value }));
  };

  const handleSubmit = () => {
    dispatch(submitArtifactInputs(editableFields));
  };

  const toggleFullscreen = () => {
    if (!isFullscreen) {
      const mainElement = document.querySelector(".main");
      if (mainElement) {
        mainElement.classList.add("panel-closed");
        mainElement.style.width = "0";
      }
      setIsFullscreen(!isFullscreen);
    } else {
      const mainElement = document.querySelector(".main");
      if (mainElement) {
        mainElement.classList.remove("panel-closed");
        mainElement.style.width = "";
      }
      setIsFullscreen(!isFullscreen);
    }
  };

  const handleMouseDown = useCallback((e) => {
    e.preventDefault();
    setIsDragging(true);
  }, []);

  const handleMouseUp = useCallback(() => {
    setIsDragging(false);
  }, []);

  const handleMouseMove = useCallback(
    (e) => {
      if (isDragging) {
        const newWidth =
          ((window.innerWidth - e.clientX) / window.innerWidth) * 100 +
          (isSidebarVisible ? 10 : 0);
        setWidth(Math.max(20, Math.min(80, newWidth)));
      }
    },
    [isDragging]
  );

  const projectList = useSelector((state) => state.project.projectList);
  const project = projectList.find((p) => p.project_id === projectId);

  useEffect(() => {
    console.log("Project list updated:", projectList);
  }, [projectList]);

  useEffect(() => {
    const chatContainer = document.querySelector(".chat-messages-container");
    if (chatContainer) {
      setMessagesWidth(chatContainer.offsetWidth);
    }
  }, []);

  const scrollToBottom = () => {
    const chatContainer = document.querySelector(".chat-messages-container");
    if (chatContainer) {
      chatContainer.scrollTo({
        top: chatContainer.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const onCloseButtonClick = () => {
    // if tooltip, hide
    const tooltipEl = document.querySelector(".custom-tooltip");
    if (tooltipEl) {
      tooltipEl.style.display = "none";
    }
    if (isFullscreen) {
      toggleFullscreen();
    }
    // if tooltip, hide
    onClose();
  };

  useEffect(() => {
    if (isDragging) {
      const chatContainer = document.querySelector(".chat-messages-container");
      if (chatContainer && chatContainer.offsetWidth !== messagesWidth) {
        // scrollToBottom();
        setMessagesWidth(chatContainer.offsetWidth);
      }
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    }
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging, handleMouseMove, handleMouseUp]);

  const colorScale = React.useMemo(() => scaleOrdinal(schemeCategory10), []);

  useEffect(() => {
    if (artifact && artifact.isDesign && svgRef.current && design) {
      const svg = d3.select(svgRef.current);
      svg.selectAll("*").remove();

      const containerWidth = Math.min(
        svgRef.current.parentNode.offsetWidth,
        1000
      );
      const binWidth = 180;
      const padding = 20;
      const leftPadding = 80;
      const topPadding = 10;
      const partRectWidth = binWidth - 10;
      const partRectHeight = 30;
      const partSpacing = 5;
      const arrowOffset = 60;
      const arrowLineWidth = 3;
      const arrowHeadSize = 4;
      const arrowHeadOffset = 10;
      const circularPathPadding = 100;
      const borderRadius = 3;
      const backgroundColor = "#f1f1f1";

      let customColors = [
        "#f28a22",
        "#d93dea",
        "#270C56",
        "#9370DB",
        "#53A3BE",
        "#336F3D",
      ];

      // Calculate dynamic bin height
      const maxParts = Math.max(
        ...design.bins.map((bin) => Object.keys(bin.part_names).length)
      );
      const binHeight = maxParts * (partRectHeight + partSpacing) + 40; // 40 for padding

      const totalWidth = Math.max(
        design.bins.length * (binWidth + 65) + leftPadding,
        containerWidth
      );

      // Calculate the actual width of the diagram
      const diagramWidth = design.bins.length * (binWidth + 60) + leftPadding;

      const getColor = (i, d) => {
        if (i < customColors.length) {
          return customColors[i];
        }
        return colorScale(i);
      };

      let maxBinHeight = 0;
      design.bins.forEach((bin) => {
        const binHeight =
          Object.keys(bin.part_names).length * (partRectHeight + partSpacing) + 40;
        maxBinHeight = Math.max(maxBinHeight, binHeight);
      });

      const totalHeight =
        maxBinHeight +
        topPadding +
        (design.design_type === "circular" ? circularPathPadding : 40);

      // Center the diagram horizontally
      const xOffset = (totalWidth - diagramWidth) / 2;

      const aspectRatio = totalWidth / totalHeight;

      const debounce = (func, delay) => {
        let timeoutId;
        return (...args) => {
          clearTimeout(timeoutId);
          timeoutId = setTimeout(() => func(...args), delay);
        };
      };

      svg.attr("viewBox", `0 0 ${totalWidth} ${totalHeight}`);
      // .attr("preserveAspectRatio", "xMidYMin meet");

      let keyboxPaddingLeft = 20;
      let keyboxPaddingTop = 0;
      // Add key box
      const keyBox = svg
        .append("g")
        .attr(
          "transform",
          `translate(${keyboxPaddingLeft}, ${keyboxPaddingTop})`
        );

      const typeIcon = keyBox
        .append("g")
        .attr("transform", `translate(100, 38)`);

      console.log(design.design_type);

      if (design.bins.length === 0) {
        svg
          .append("text")
          .attr("x", leftPadding)
          .attr("y", topPadding)
          .text("No bins created. Use the chat to create your design!")
          .style("font-size", "14px")
          .style("fill", "#111");
      }

      const binGroup = svg
        .append("g")
        .attr(
          "transform",
          `translate(${leftPadding + xOffset}, ${topPadding + 40})`
        );

      svg
        .append("defs")
        .append("marker")
        .attr("id", "arrowhead")
        .attr("viewBox", "0 -5 10 10")
        .attr("refX", 5)
        .attr("refY", 0)
        .attr("markerWidth", arrowHeadSize)
        .attr("markerHeight", arrowHeadSize)
        .attr("orient", "auto")
        .append("path")
        .attr("d", "M0,-5L10,0L0,5")
        .attr("fill", "#111");

      design.bins.forEach((bin, index) => {
        let binHeight =
          Object.keys(bin.part_names).length * (partRectHeight + partSpacing) + 40;

        let binHeightOffset = (maxBinHeight - binHeight) / 2;

        const binG = binGroup
          .append("g")
          .attr(
            "transform",
            `translate(${index * (binWidth + 60)}, ${binHeightOffset})`
          );

        const binInteractionG = binG.append("g").style("cursor", "pointer");

        binInteractionG
          .append("rect")
          .attr("width", binWidth)
          .attr("height", binHeight)
          .attr("fill", "none")
          .attr("stroke-opacity", 0.1)
          .attr("stroke", getColor(index, bin));

        binG
          .append("rect")
          .attr("x", 0)
          .attr("y", 0)
          .attr("width", binWidth)
          .attr("height", 0)
          .attr("fill", getColor(index, bin))
          .attr("fill-opacity", 0.2);

        binG
          .append("line")
          .attr("x1", 0)
          .attr("y1", 0)
          .attr("x2", binWidth)
          .attr("y2", 0)
          .attr("stroke", getColor(index, bin))
          .attr("stroke-width", 2);

        binG
          .append("rect")
          .attr("x", 0)
          .attr("y", 0)
          .attr("width", binWidth)
          .attr("height", binHeight)
          .attr("fill", getColor(index, bin))
          .attr("opacity", 0.05);

        binG
          .append("text")
          .attr("x", binWidth / 2)
          .attr("y", -10)
          .attr("text-anchor", "middle")
          .text(`${bin.name}${bin.optional ? " (optional)" : ""}`)
          .style("font-size", "12px")
          .style("font-weight", "500")
          .attr("fill", "#111")
          .style("pointer-events", "none");

        const partGroup = binG
          .append("g")
          .attr("transform", `translate(5, 20)`);

        Object.entries(bin.part_names).forEach(([partName, partType], partIndex) => {
          const partG = partGroup
            .append("g")
            .datum(partName)
            .attr(
              "transform",
              `translate(0, ${partIndex * (partRectHeight + partSpacing)})`
            );

          const partInteractionG = partG.append("g").style("cursor", "pointer");

          partInteractionG.on("click.open", (event, d) => {
            handleClick(d);
          });

          const getPartSource = (type) => {
            switch (type) {
              case "synthesis":
              case "synthesis_to_order":
                return "Synthesis";
              case "pcr":
                return "PCR";
              case "digest":
                return "Digest";
              default:
                return type.charAt(0).toUpperCase() + type.slice(1);
            }
          };

          const handleMouseOver = (event) => {
            const rect = event.target.getBoundingClientRect();
            const tooltipText = `
              ${partName}<br>
              Source: ${getPartSource(partType)}
              ${partType === "synthesis_to_order" ? "<br>To Order" : ""}
            `;
            setTooltip({
              visible: true,
              text: tooltipText,
              x: rect.left + window.scrollX,
              y: rect.top + window.scrollY - 30,
            });
            d3.select(event.target).attr("fill", "#e2e2e2");
            d3.select(event.target).attr("stroke-opacity", 1);
          };

          const handleMouseOut = (event) => {
            setTooltip({ visible: false, text: "", x: 0, y: 0 });
            d3.select(event.target).attr("stroke-opacity", 0.5);
            d3.select(event.target).attr("fill", partType === "synthesis_to_order" ? "#e6f3ff" : backgroundColor);
          };

          const rect = partInteractionG
            .append("rect")
            .attr("width", partRectWidth)
            .attr("height", partRectHeight)
            .on("mouseover", handleMouseOver)
            .on("mouseout", handleMouseOut)
            .style("pointer-events", "all")
            .attr("fill", partType === "synthesis_to_order" ? "#e6f3ff" : backgroundColor)
            .attr("stroke", getColor(index, bin))
            .attr("stroke-opacity", 0.5)
            .attr("rx", borderRadius)
            .attr("ry", borderRadius);

          // Add SVG icon
          const iconWidth = 30;
          const iconHeight = 30;
          const iconX = 5;
          const iconY = (partRectHeight - iconHeight) / 2;

          let iconPath;
          switch (partType) {
            case "pcr":
              iconPath = "/images/pcr_rep.svg";
              break;
            case "digest":
              iconPath = "/images/digest_rep.svg";
              break;
            case "synthesis":
            case "synthesis_to_order":
            default:
              iconPath = "/images/synth_rep.svg";
              break;
          }

          partG
            .append("image")
            .attr("x", iconX)
            .attr("y", iconY)
            .attr("width", iconWidth)
            .attr("height", iconHeight)
            .attr("href", iconPath);

          partG
            .append("text")
            .attr("x", iconWidth + 10)
            .attr("y", partRectHeight / 2)
            .attr("dominant-baseline", "middle")
            .text(partName)
            .attr("fill", "#111")
            .style("font-size", "12px")
            .style("pointer-events", "none")
            .each(function () {
              const self = d3.select(this);
              let textLength = self.node().getComputedTextLength();
              let text = self.text();
              while (textLength > partRectWidth - iconWidth - 15 && text.length > 0) {
                text = text.slice(0, -1);
                self.text(text + "...");
                textLength = self.node().getComputedTextLength();
              }
            });
        });

        if (index < design.bins.length - 1) {
          const arrowStart = (index + 1) * (binWidth + 60) - arrowOffset;
          const arrowEnd = (index + 1) * (binWidth + 60) - arrowHeadOffset;

          binGroup
            .append("line")
            .attr("x1", arrowStart)
            .attr("y1", binHeight / 2 + binHeightOffset)
            .attr("x2", arrowEnd)
            .attr("y2", binHeight / 2 + binHeightOffset)
            .attr("stroke", "#111")
            .attr("stroke-width", arrowLineWidth)
            .attr("marker-end", "url(#arrowhead)");
        }
      });
      if (design.design_type === "circular" && design.bins.length > 0) {
        const lastBinX = (design.bins.length - 1) * (binWidth + 60);
        const elbowBottom = binHeight + circularPathPadding / 2;
        const elbowLeft = -arrowOffset;
        const curveRadius = 30; // Adjust this value to change the curve sharpness

        const pathData = `M${lastBinX + binWidth},${binHeight / 2}
                          L${lastBinX + binWidth + arrowOffset - curveRadius},${binHeight / 2
          }
                          Q${lastBinX + binWidth + arrowOffset},${binHeight / 2
          } ${lastBinX + binWidth + arrowOffset},${binHeight / 2 + curveRadius}
                          L${lastBinX + binWidth + arrowOffset},${elbowBottom - curveRadius
          }
                          Q${lastBinX + binWidth + arrowOffset
          },${elbowBottom} ${lastBinX + binWidth + arrowOffset - curveRadius
          },${elbowBottom}
                          L${elbowLeft + curveRadius},${elbowBottom}
                          Q${elbowLeft},${elbowBottom} ${elbowLeft},${elbowBottom - curveRadius
          }
                          L${elbowLeft},${binHeight / 2 + curveRadius}
                          Q${elbowLeft},${binHeight / 2} ${elbowLeft + curveRadius
          },${binHeight / 2}
                          L${arrowHeadOffset - 20},${binHeight / 2}`;

        binGroup
          .append("path")
          .attr("d", pathData)
          .attr("fill", "none")
          .attr("stroke", "#111")
          .attr("stroke-width", arrowLineWidth)
          .attr("marker-end", "url(#arrowhead)");
      }
    }
  }, [artifact, design, dispatch]);

  useEffect(() => {
    if (artifact) {
      const sequenceNames = Object.keys(artifact);
      setSelectedSequence(sequenceNames[0]);
      //   scrollToBottom();
    }
  }, [artifact]);

  useEffect(() => {
    const tooltipEl = document.createElement("div");
    tooltipEl.className = "custom-tooltip";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.display = "none";
    tooltipEl.style.backgroundColor = "rgba(0, 0, 0, 0.8)";
    tooltipEl.style.color = "#dfdfdf";
    tooltipEl.style.padding = "5px";
    tooltipEl.style.borderRadius = "3px";
    tooltipEl.style.fontSize = "12px";
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.zIndex = "1000";
    document.body.appendChild(tooltipEl);

    return () => {
      document.body.removeChild(tooltipEl);
    };
  }, []);

  useEffect(() => {
    const tooltipEl = document.querySelector(".custom-tooltip");
    if (tooltip.visible) {
      tooltipEl.style.display = "block";
      tooltipEl.style.left = `${tooltip.x}px`;
      tooltipEl.style.top = `${tooltip.y}px`;
      tooltipEl.innerHTML = tooltip.text;
    } else {
      tooltipEl.style.display = "none";
    }
  }, [tooltip]);

  const renderMarkdownContent = () => {
    if (!artifact || !artifact.content) return null;

    const lines = artifact.content.split('\n');
    let tableStartIndex = -1;

    return (
      <div className="markdown-content">
        {lines.map((line, index) => {
          if (line.startsWith('# ')) {
            return <h1 key={index}>{line.slice(2)}</h1>;
          } else if (line.includes('<input')) {
            return renderInput(line, index);
          } else if (line.includes('<checkbox')) {
            return renderCheckbox(line, index);
          } else if (line.includes('<table')) {
            tableStartIndex = index;
            return null; // We'll render the table separately
          } else if (line.includes('</table>')) {
            const tableContent = lines.slice(tableStartIndex, index + 1);
            tableStartIndex = -1;
            return renderTable(tableContent, index);
          } else if (line.includes('<dropdown')) {
            return renderDropdown(line, index);
          } else if (line.includes('<span id="result"')) {
            return renderResult(index);
          }
          return tableStartIndex === -1 ? <p key={index}>{line}</p> : null;
        })}
      </div>
    );
  };

  const renderInput = (line, index) => {
    const [label, input] = line.split(':');
    const match = input.match(/id="(\w+)"/);
    if (match) {
      const id = match[1];
      return (
        <div key={index} className="input-group">
          <label htmlFor={id}>{label.trim()}:</label>
          <input
            id={id}
            type="text"
            value={editableFields[id] || ''}
            onChange={(e) => handleInputChange(id, e.target.value)}
          />
        </div>
      );
    }
  };

  const renderCheckbox = (line, index) => {
    const match = line.match(/id="(\w+)"/);
    if (match) {
      const id = match[1];
      const label = line.split('>')[1].split('<')[0];
      return (
        <div key={index} className="checkbox-group">
          <input
            id={id}
            type="checkbox"
            checked={editableFields[id] || false}
            onChange={() => handleInputChange(id, null, 'checkbox')}
          />
          <label htmlFor={id}>{label}</label>
        </div>
      );
    }
  };

  const renderTable = (tableLines, startIndex) => {
    return (
      <table key={startIndex} className="editable-table">
        <thead>
          <tr>
            {tableLines[1].split('|').map((header, index) => (
              <th key={index}>{header.trim()}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableLines.slice(3, -1).map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.split('|').map((cell, cellIndex) => {
                const editableMatch = cell.match(/<editable id="([^"]+)">([^<]+)<\/editable>/);
                if (editableMatch) {
                  const id = editableMatch[1];
                  const value = editableMatch[2];
                  return (
                    <td key={cellIndex}>
                      <input
                        type="text"
                        id={id}
                        value={editableFields[id] || value}
                        onChange={(e) => handleInputChange(id, e.target.value)}
                      />
                    </td>
                  );
                } else {
                  return <td key={cellIndex}>{cell.trim()}</td>;
                }
              })}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const renderDropdown = (line, index) => {
    const match = line.match(/id="(\w+)"/);
    const optionsMatch = line.match(/options="([^"]*)"/);
    if (match && optionsMatch) {
      const id = match[1];
      const options = optionsMatch[1].split(',');
      if (!dropdownOptions[id]) {
        setDropdownOptions(prev => ({ ...prev, [id]: options }));
      }
      return (
        <div key={index} className="dropdown-group">
          <label htmlFor={id}>{line.split(':')[0].trim()}:</label>
          <input
            id={id}
            type="text"
            value={editableFields[id] || ''}
            onChange={(e) => handleInputChange(id, e.target.value)}
            list={`${id}-options`}
          />
          <datalist id={`${id}-options`}>
            {dropdownOptions[id]?.filter(option => 
              option.toLowerCase().includes((editableFields[id] || '').toLowerCase())
            ).map((option, optionIndex) => (
              <option key={optionIndex} value={option} />
            ))}
          </datalist>
        </div>
      );
    }
  };

  const renderResult = (index) => {
    return (
      <div key={index} className="result">
        <span>Result: </span>
        <span id="result">{editableFields.result || ''}</span>
      </div>
    );
  };

  useEffect(() => {
    if (artifact && artifact.type === "markdown") {
      setMarkdownContent(artifact.content);
    }
  }, [artifact]);

  const renderHTMLContent = () => {
    if (artifact && artifact.type === "html") {
      return (
        <div 
          className="html-content"
          dangerouslySetInnerHTML={{ __html: artifact.content }}
        />
      );
    }
    return null;
  };

  if (!artifact) {
    return null;
  }

  const handleGenerateInstructions = () => {
    setIsPopupOpen(true); // Show the popup
  };

  const handleConfirmGenerateInstructions = () => {
    setIsPopupOpen(false); // Hide the popup
    dispatch(clearGenerateInstructionsError());
    dispatch(generateProjectInstructions({projectId, override: "false"})); // Add "false" as the override parameter
  };

  const handleCancelGenerateInstructions = () => {
    setIsPopupOpen(false); // Hide the popup
  };

  const handleDesignClick = () => {
    if (design) {
      dispatch(updateArtifact({ ...design, isDesign: true }));
    }
  };

  const handleClick = (d) => {
    console.log(project.files);
    // if tooltip, hide
    setTooltip({ visible: false, text: "", x: 0, y: 0 });
    // find the key of the object in files that where files[key].name is equal to d
    const key = Object.keys(project.files).find(
      (key) => project.files[key].name === d
    );
    console.log(key);

    if (key) {
      dispatch(fetchArtifactData(key));
    }
  };

  const handleForwardClick = () => {
    if (design && design.labkick_id && design.next_design_id) {
      dispatch(navigateDesign({ projectId: projectId, designId: design.labkick_id, direction: "forward" }));
    }
  };

  const handleBackClick = () => {
    if (design && design.labkick_id && design.previous_design_id) {
      dispatch(navigateDesign({ projectId: projectId, designId: design.labkick_id, direction: "back" }));
    }
  };

  const renderDesignContent = () => {
    return (
      <div
        className="design-content"
        style={{ overflowY: "auto", height: "100%", paddingTop: "20px" }}
      >
        <div className="design-specification">
          <div className="design-specification-box">
            <div className="header">Design Specification</div>
            <p>
              <span className="label">Type</span>{" "}
              {design.design_type === "circular" && (
                <span className="icon circle">
                  <TbCircleDashed />
                </span>
              )}
              {design.design_type === "linear" && (
                <span className="icon line">
                  <MdOutlineHorizontalRule />
                </span>
              )}
              <span>
                {design.design_type.charAt(0).toUpperCase() +
                  design.design_type.slice(1)}
              </span>
            </p>
            <p>
              <span className="label">Assembly Method</span>{" "}
              <span>
                {design.assembly_method_type.toLowerCase() === 'golden_gate' ? 'Golden Gate' :
                 design.assembly_method_type.toLowerCase() === 'gibson' ? 'Gibson' :
                 design.assembly_method_type.toLowerCase() === 'traditional' ? 'Traditional' :
                 design.assembly_method_type.charAt(0).toUpperCase() + design.assembly_method_type.slice(1)}
              </span>
            </p>
            <p>
              <span className="label">Assembly Mode</span>{" "}
              <span>
                {design.assembly_mode.charAt(0).toUpperCase() +
                  design.assembly_mode.slice(1)}
              </span>
            </p>
            <p>
              <span className="label">Restriction Enzymes</span>{" "}
              <span>{design.restriction_enzymes ? design.restriction_enzymes.join(", ") : "None"}</span>
            </p>
          </div>
        </div>
        <div
          className="design-content-container"
          ref={svgContainerRef}
          style={{ width: "100%", height: "500px" }}
        >
          <svg ref={svgRef}></svg>
        </div>
        {design && design.ready_to_clone && (
          <div className="instructions-section">
            <button
              className="generate-instructions-button"
              onClick={handleGenerateInstructions}
              disabled={isGeneratingInstructions}
            >
              <FaMagic size={24} />
              <span>
                {isGeneratingInstructions
                  ? "Generating..."
                  : "Generate Instructions"}
              </span>
            </button>
            {generateInstructionsError && (
              <div className="error-message">{generateInstructionsError}</div>
            )}
            {instructions && (
              <div className="instructions-content">
                <h3>Instructions:</h3>
                <pre>{instructions}</pre>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  const renderSequenceContent = () => {
    if (!artifact) return null;

    const sequenceNames = Object.keys(artifact);
    const currentSequence = selectedSequence || sequenceNames[0];
    const sequenceData = artifact[currentSequence];

    if (!sequenceData) return null;

    return (
      <div className="sequence-content">
        <div className="viewer-controls">
          <div className="dropdowns-container">
            <div className="viewer-type-container">
              <label
                className="viewer-type-label"
                htmlFor="viewer-type-dropdown"
              >
                Viewer
              </label>
              <select
                id="viewer-type-dropdown"
                className="viewer-type-dropdown"
                value={viewerType}
                onChange={(e) => setViewerType(e.target.value)}
              >
                <option value="linear">Linear</option>
                <option value="circular">Circular</option>
                <option value="both">Both (Circular Left)</option>
                <option value="both_flip">Both (Circular Right)</option>
              </select>
            </div>

            {sequenceNames.length > 1 && (
              <div className="sequence-select-container">
                <label
                  className="sequence-select-label"
                  htmlFor="sequence-select-dropdown"
                >
                  Sequence
                </label>
                <select
                  id="sequence-select-dropdown"
                  className="sequence-select-dropdown"
                  value={currentSequence}
                  onChange={(e) => setSelectedSequence(e.target.value)}
                >
                  {sequenceNames.map((name) => (
                    <option key={name} value={name}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
        </div>
        <SeqViz
          name={sequenceData.name || currentSequence}
          seq={sequenceData.seq}
          annotations={sequenceData.annotations}
          viewer={viewerType}
        />
      </div>
    );
  };

  // Determine which content to render based on the artifact type
  let content;
  if (artifact.type === "html") {
    content = renderHTMLContent();
  } else if (artifact.isDesign) {
    content = renderDesignContent();
  } else if (artifact.type === "markdown") {
    content = renderMarkdownContent();
  } else {
    content = renderSequenceContent();
  }

  return (
    <div
      ref={panelRef}
      className={`artifact-panel ${isFullscreen ? "fullscreen-panel" : ""}`}
      style={{ flexBasis: `${width}%`, maxWidth: `${width}%` }}
    >
      <div className="resize-handle" onMouseDown={handleMouseDown}></div>
      <div className="artifact-buttons-container">
        <button className="fullscreen-button" onClick={toggleFullscreen}>
          {isFullscreen ? (
            <BiExitFullscreen size={24} />
          ) : (
            <BiFullscreen size={24} />
          )}
        </button>
        {artifact && artifact.isDesign && (
          <>
            <button className="back-button" onClick={handleBackClick}>
              <FaArrowLeft size={20} />
            </button>
            <button className="forward-button" onClick={handleForwardClick}>
              <FaArrowRight size={20} />
            </button>
          </>
        )}
      </div>

      {artifact && !artifact.isDesign && (
        <button className="back-to-design-button" onClick={handleDesignClick}>
          <FaArrowLeft size={24} />
          Back to Design
        </button>
      )}
      <button className="close-button no-hover" onClick={onCloseButtonClick}>
        &times;
      </button>

      {isPopupOpen && (
        <div className="artifact-confirmation-popup-overlay">
          <div className="artifact-confirmation-popup-dialog">
            <h3>Generate Instructions</h3>
            <p>Are you ready to generate instructions? Once you generate instructions you will only be able to modify the design twice. Please make sure you have all your parts you need as part of your design.</p>
            <div className="artifact-confirmation-popup-buttons">
              <button
                onClick={handleConfirmGenerateInstructions}
                className="artifact-confirm-button"
              >
                Yes, Generate
              </button>
              <button
                onClick={handleCancelGenerateInstructions}
                className="artifact-cancel-button"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {artifact && artifact.type === "markdown" && (
        <button className="submit-button" onClick={handleSubmit}>
          Submit
        </button>
      )}

      <div className="artifact-content">
        {content}
      </div>
    </div>
  );
}

export default ArtifactPanel;