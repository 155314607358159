import React, { useState, useRef, useEffect } from "react";
import {
  BiSolidUserCircle,
  BiFolderOpen,
  BiFolder,
  BiFile,
} from "react-icons/bi";
import "../style/Sidebar.css";
import { processProjectFiles } from "../helpers.js";
import FileExplorerModal from "./FileExplorerModal.js";
import UserPreferencesModal from "./UserPreferencesModal.js";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentProjectId,
  updateArtifact,
  updateModal,
  toggleSidebar,
  fetchProjects,
  fetchChat,
  resetChat,
  fetchDesign,
  fetchArtifactData,
} from "../store/project.js";
import { downloadFiles } from "../api.js";
import { useAuth0 } from "@auth0/auth0-react";
import { updateAccessToken, fetchUser, fetchUserCredits } from "../store/user.js";
import { BiPencil } from "react-icons/bi"; // Add this import
import PaymentModal from "./PaymentModal.js"; // Add this import

// import sidebarIcon from "../icons/sidebar.svg";

function Sidebar() {
  const [isUserSignedIn, setIsUserSignedIn] = useState(false);

  const [activeDropdown, setActiveDropdown] = useState(null);
  const [openFolders, setOpenFolders] = useState({});
  const [projectFiles, setProjectFiles] = useState({});

  const dropdownRef = useRef(null);
  const projectItemRef = useRef(null);
  const userDropdownRef = useRef(null);

  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.access_token);

  const isVisible = useSelector((state) => state.project.isSidebarVisible); // With this if selectIsSidebarVisible is not a separate function
  const user_id = useSelector((state) => state.user.user_id);
  const usageCredits = useSelector((state) => state.user.usageCredits);

  const { projectList, currentProjectId, modal } = useSelector(
    (state) => state.project
  );

  const {
    user,
    isAuthenticated,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
  } = useAuth0();

  const [userDropdownOpen, setUserDropdownOpen] = useState(false);

  const [hasFetchedProjects, setHasFetchedProjects] = useState(false);

  const chat = useSelector((state) => state.project.chat);

  const design = useSelector((state) => state.project.design);
  const [designRequested, setDesignRequested] = useState(false);

  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false); // Add this state

  const handleDesignClick = () => {
    if (design) {
      dispatch(updateArtifact({ ...design, isDesign: true }));
    }
  };

  const handleProjectClick = (event, projectId) => {
    // Only change the project if the click is outside the three dots
    if (!event.target.closest(".project-menu-icon")) {
      dispatch(setCurrentProjectId(projectId));
    }
  };

  const toggleDropdown = (event, projectId) => {
    event.stopPropagation(); // Prevent project selection
    setActiveDropdown((prevActiveDropdown) =>
      prevActiveDropdown === projectId ? null : projectId
    );
  };

  // Handle Dropdown for Rename / Open Modal
  const handleProjectRename = (event, projectId) => {
    event.stopPropagation(); // Prevent project selection
    const projectToRename = projectList.find((p) => p.project_id === projectId);
    dispatch(updateModal({ name: "rename_project", data: projectToRename }));
    setActiveDropdown(null);
  };

  // Handle Dropdown for Delete / Open Modal
  const handleDeleteProject = (event, projectId) => {
    event.stopPropagation(); // Prevent project selection
    dispatch(
      updateModal({
        name: "delete_project",
        data: projectList.find((p) => p.project_id === projectId),
      })
    );
    setActiveDropdown(null);
  };

  // Handle Dropdown for Delete File / Open Modal
  const handleFileDelete = (event, fileData) => {
    event.stopPropagation();
    dispatch(updateModal({ name: "delete_file", data: fileData }));
  };

  const handleProjectDownload = (event, projectId) => {
    event.stopPropagation();
    downloadFiles(projectId, "project", token);
    setActiveDropdown(null);
  };

  const toggleFolder = (folderId) => {
    setOpenFolders((prev) => ({
      ...prev,
      [folderId]: !prev[folderId],
    }));
  };

  const renderProjectFiles = (projectId) => {
    if (projectId !== currentProjectId) return null;
    const files = projectFiles[projectId] || [];
    return (
      <ul className="file-tree">
        <div className="design-button-container">
          <button className="design-button" onClick={handleDesignClick}>
            <BiPencil size={20} />
            Design
          </button>
        </div>

        {renderFileTree(files)}
      </ul>
    );
  };

  const renderFileTree = (items) => {
    return items.map((item) => {
      if (item.type === "file") {
        return (
          <li
            key={item.uuid}
            className="file-item"
            onClick={(e) => handleFileClick(e, item)}
            title={item.name}
          >
            <BiFile className="file-item-icon" />
            <span className="file-item-name">{item.name}</span>
            <img
              src="/images/trash.svg"
              alt="Delete"
              className="file-delete-icon"
              onClick={(e) => handleFileDelete(e, item)}
            />
          </li>
        );
      } else if (item.type === "folder") {
        const isOpen = openFolders[item.id];
        return (
          <React.Fragment key={item.id}>
            <li
              className="folder-item"
              onClick={() => toggleFolder(item.id)}
              title={item.name}
            >
              {isOpen ? (
                <BiFolderOpen className="file-item-icon" />
              ) : (
                <BiFolder className="file-item-icon" />
              )}
              <span className="file-item-name">{item.name}</span>
            </li>
            {isOpen && (
              <ul className="indented">{renderFileTree(item.children)}</ul>
            )}
          </React.Fragment>
        );
      }
      return null;
    });
  };

  const handleFileClick = (event, fileData) => {
    event.stopPropagation();
    const fileId = fileData.uuid;
    console.log("fileId", fileId);
    dispatch(fetchArtifactData(fileId));
  };

  const handlePaymentClick = () => {
    setIsPaymentModalOpen(true);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        projectItemRef.current &&
        !projectItemRef.current.contains(event.target) &&
        userDropdownRef.current &&
        !userDropdownRef.current.contains(event.target)
      ) {
        setActiveDropdown(null);
        setUserDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (currentProjectId) {
      const currentProject = projectList.find(
        (p) => p.project_id === currentProjectId
      );
      if (currentProject) {
        const files = processProjectFiles(currentProject);
        setProjectFiles((prevFiles) => ({
          ...prevFiles,
          [currentProjectId]: files,
        }));

        // Set the folder open states based on file count
        const folderStates = {};
        files.forEach((folder) => {
          if (folder.type === "folder") {
            const fileCount = folder[`${folder.name.toLowerCase()}FileCount`];
            folderStates[folder.id] = fileCount <= 10;
          }
        });
        setOpenFolders((prev) => ({
          ...prev,
          ...folderStates,
        }));
      }
    }
  }, [currentProjectId, projectList]);

  const handleAuthAction = () => {
    if (isAuthenticated) {
      logout({ returnTo: window.location.origin });
    } else {
      loginWithRedirect();
    }
  };

  const fetchAccessToken = async () => {
    try {
      const accessTokenTmp = await getAccessTokenSilently();
      return accessTokenTmp;
    } catch (error) {
      console.error("Error getting access token:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const accessTokenTmp = await fetchAccessToken();
        if (accessTokenTmp) {
          dispatch(updateAccessToken(accessTokenTmp));
          console.log("User Details:", user, "with token", accessTokenTmp);
          return dispatch(
            fetchUser({ token: accessTokenTmp, user_auth0: user })
          );
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    if (isAuthenticated && !hasFetchedProjects) {
      fetchUserData()
        .then((result) => {
          console.log("Result:", result);
          if (result && result.payload && result.payload.data.data.user_id) {
            dispatch(fetchProjects(result.payload.data.data.user_id));
            setHasFetchedProjects(true);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [isAuthenticated, hasFetchedProjects]);

  useEffect(() => {
    if (currentProjectId) {
      dispatch(fetchChat(currentProjectId));
      dispatch(fetchDesign(currentProjectId));
      dispatch(updateArtifact(null));
    } else {
      dispatch(resetChat(currentProjectId));
    }
  }, [currentProjectId]);

  useEffect(() => {
    console.log("Chat:", chat[currentProjectId]);
  }, [chat]);

  const toggleUserDropdown = (event) => {
    event.stopPropagation();
    setUserDropdownOpen(!userDropdownOpen);
  };

  useEffect(() => {
    if (isAuthenticated && user_id) {
      dispatch(fetchUserCredits());
    }
  }, [isAuthenticated, user_id, dispatch]);

  return (
    <>
      {!isVisible && (
        <button
          className="sidebar-toggle"
          onClick={() => dispatch(toggleSidebar())}
        >
          <img src="/images/sidebar.svg" alt="Toggle Sidebar" />
        </button>
      )}
      <section className={`sidebar ${isVisible ? "" : "hidden"}`}>
        <div className="sidebar-content">
          <div className="sidebar-top">
            <div className="sidebar-header">
              <img
                src="/images/sidebar.svg"
                alt="Sidebar"
                className="sidebar-icon"
                onClick={() => dispatch(toggleSidebar())}
              />
            </div>
            <div className="project-header">
              <div className="project-header-name">Projects</div>
              <img
                src="/images/new_project.svg"
                alt="New Project"
                className="new-project-icon"
                onClick={() =>
                  dispatch(updateModal({ name: "create_project", data: null }))
                }
              />
            </div>
            <div className="project-list">
              <ul>
                {projectList.length > 0 ? (
                  projectList.map((project) => (
                    <div
                      key={project.project_id}
                      className={`project-group ${project.project_id === currentProjectId
                          ? "selected"
                          : ""
                        }`}
                    >
                      <li
                        className={`project-item ${project.project_id === currentProjectId
                            ? "selected"
                            : ""
                          }`}
                        ref={projectItemRef}
                        onClick={(event) =>
                          handleProjectClick(event, project.project_id)
                        }
                        title={project.project_name}
                      >
                        <span className="project-name">
                          {project.project_name}
                        </span>
                        <img
                          src="/images/three-dots.svg"
                          alt="Menu"
                          className="project-menu-icon"
                          onClick={(event) =>
                            toggleDropdown(event, project.project_id)
                          }
                        />
                        {activeDropdown === project.project_id && (
                          <div
                            ref={dropdownRef}
                            className="project-dropdown"
                            onMouseLeave={() => setActiveDropdown(null)}
                          >
                            <button
                              className="dropdown-button"
                              onClick={(event) =>
                                handleProjectRename(event, project.project_id)
                              }
                            >
                              <span>Rename</span>
                              <img
                                src="/images/pencil.svg"
                                alt="Rename"
                                className="dropdown-icon"
                              />
                            </button>
                            <button
                              className="dropdown-button"
                              onClick={(event) => {
                                event.stopPropagation();
                                dispatch(
                                  updateModal({
                                    name: "dna_archive_project",
                                    data: { projectId: project.project_id },
                                  })
                                );
                                setActiveDropdown(null);
                              }}
                            >
                              <span>Add File</span>
                              <img
                                src="/images/plus.svg"
                                alt="Add File"
                                className="dropdown-icon"
                              />
                            </button>
                            <button
                              className="dropdown-button"
                              onClick={(event) =>
                                handleProjectDownload(event, project.project_id)
                              }
                            >
                              <span>Download</span>
                              <img
                                src="/images/download.svg"
                                alt="Download"
                                className="dropdown-icon"
                              />
                            </button>
                            <button
                              className="dropdown-button"
                              onClick={(event) =>
                                handleDeleteProject(event, project.project_id)
                              }
                            >
                              <span>Delete</span>
                              <img
                                src="/images/trash.svg"
                                alt="Delete"
                                className="dropdown-icon"
                              />
                            </button>
                          </div>
                        )}
                      </li>

                      {renderProjectFiles(project.project_id)}
                    </div>
                  ))
                ) : (
                  <div></div>
                )}
              </ul>
            </div>
          </div>
        </div>

        <div className="sidebar-info">
          {isAuthenticated ? (
            <>
              <div
                className="sidebar-info-item"
                onClick={handlePaymentClick}
                style={{ cursor: "pointer" }}
              >
                <div className="sidebar-bottom-img-container">
                  <img
                    src="/images/credit.svg"
                    alt="Coins"
                    className="sidebar-bottom-img"
                  />
                </div>
                <p className="sidebar-bottom-text">
                  {usageCredits === 0 ? "Buy Credits" : usageCredits === 1 ? `${usageCredits} Credit` : `${usageCredits} Credits`}
                </p>
              </div>
              <div
                className="sidebar-info-item sidebar-info-item-dna"
                onClick={() =>
                  dispatch(
                    updateModal({ name: "dna_archive_general", data: null })
                  )
                }
                style={{ cursor: "pointer" }}
              >
                <div className="sidebar-bottom-img-container">
                  <img
                    src="/images/dna-archive-fix.svg"
                    alt="DNA"
                    className="sidebar-bottom-img"
                  />
                </div>
                <p className="sidebar-bottom-text">DNA Library</p>
              </div>
              <div
                className="sidebar-info-item user-dropdown-trigger"
                onClick={toggleUserDropdown}
                ref={userDropdownRef}
                style={{ cursor: "pointer", position: "relative" }}
              >
                <div className="sidebar-bottom-img-container">
                  <img
                    src={user.picture || "/images/default-user-icon.png"}
                    alt={user.name}
                    width="20"
                    height="20"
                    className="sidebar-bottom-img user-picture"
                  />
                </div>
                <p className="sidebar-bottom-text">{user.name}</p>
                {userDropdownOpen && (
                  <div
                    className="user-dropdown"
                    onMouseLeave={() => setUserDropdownOpen(false)}
                  >
                    <button
                      className="dropdown-button"
                      onClick={() => {
                        dispatch(
                          updateModal({ name: "user_preferences", data: null })
                        );
                        setUserDropdownOpen(false);
                      }}
                    >
                      Preferences
                    </button>
                    <button
                      className="dropdown-button"
                      onClick={() => {
                        logout({
                          logoutParams: {
                            returnTo: window.location.origin
                          }
                        })
                        setUserDropdownOpen(false);
                      }}
                    >
                      Log Out
                    </button>
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <button
                className="auth-button"
                onClick={() => loginWithRedirect()}
              >
                Log In
              </button>
              <button
                className="auth-button"
                onClick={() =>
                  loginWithRedirect({
                    authorizationParams: {
                      screen_hint: "signup",
                      prompt: "login",
                    },
                  })
                }
              >
                Sign Up
              </button>
            </>
          )}
        </div>
        {(modal.name === "dna_archive_general" ||
          modal.name === "dna_archive_project" ||
          modal.name === "upload_general" ||
          modal.name === "upload_project") && <FileExplorerModal />}
        {modal.name === "user_preferences" && <UserPreferencesModal />}
        {isPaymentModalOpen && <PaymentModal onClose={() => setIsPaymentModalOpen(false)} />} {/* Add this line */}
      </section>
    </>
  );
}

export default Sidebar;
