const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

const fetchWithToken = async (url, options = {}, token) => {
    const defaultHeaders = {
        'Content-Type': 'application/json',
    };

    if (token) {
        defaultHeaders['authorization'] = `Bearer ${token}`;
    }

    const mergedOptions = {
        ...options,
        headers: {
            ...defaultHeaders,
            ...options.headers,
        },
    };

    return fetch(url, mergedOptions);
};

export const handleLogin = async ({ token, user_auth0 }) => {
    try {
        console.log('handleLogin called with:', { user_auth0 });
        const response = await fetchWithToken(`${API_URL}/api/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user_auth0: user_auth0 || null
            }),
            credentials: 'include',
        }, token);
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Failed to handle Google login');
        }
        return response.json();
    } catch (error) {
        console.error('Error in handleLogin:', error);
        throw error;
    }
}

export const getCredential = async () => {
    try {
        console.log('getCredential called');
        const response = await fetch(`${API_URL}/api/get-credential`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (!response.ok) {
            throw new Error('Failed to fetch credential');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error in getCredential:', error);
        return null;
    }
}

export const createProject = async (projectName, user_id, token) => {
    console.log('createProject called with:', { projectName, user_id });
    const response = await fetchWithToken(`${API_URL}/api/create-project`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ project_name: projectName, user_id: user_id }),
    }, token);

    if (!response.ok) {
        throw new Error('Failed to create project');
    }

    const result = await response.json();
    return result.status === 'success' ? result.data : Promise.reject(result.message);
};

export const renameProject = async (projectId, newName, user_id, token) => {
    console.log('renameProject called with:', { projectId, newName, user_id });
    const response = await fetchWithToken(`${API_URL}/api/rename-project`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ project_id: projectId, new_name: newName, user_id: user_id }),
    }, token);

    if (!response.ok) {
        throw new Error('Failed to rename project');
    }

    const result = await response.json();
    return result.status === 'success' ? JSON.parse(result.data).projects : Promise.reject(result.message);
};

export const deleteProjectPermanent = async (projectId, user_id, token) => {
    console.log('deleteProjectPermanent called with:', { projectId, user_id });
    const response = await fetchWithToken(`${API_URL}/api/delete-project`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ project_id: projectId, user_id: user_id }),
    }, token);

    if (!response.ok) {
        throw new Error('Failed to delete project');
    }

    const result = await response.json();
    return result.status === 'success' ? JSON.parse(result.data).projects : Promise.reject(result.message);
};

export const downloadFiles = async (id, type, token) => {
    try {
        console.log('downloadFiles called with:', { id, type });
        const response = await fetchWithToken(`${API_URL}/api/download-project`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id: id, type: type }),
        }, token);

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        console.log('Response:', response);
        console.log('Headers:', [...response.headers.entries()]);

        const suggestedFilename = response.headers.get('X-Suggested-Filename');
        console.log('Suggested filename:', suggestedFilename);

        const contentType = response.headers.get('Content-Type');
        console.log('Content-Type:', contentType);

        if (type === 'file') {
            let fileData;
            if (contentType && contentType.includes('application/json')) {
                fileData = await response.json();
                console.log('JSON file data:', fileData);
            } else {
                fileData = await response.text();
                console.log('Text file data:', fileData);
            }

            const blob = new Blob([fileData], { type: contentType || 'application/octet-stream' });
            console.log('File Blob:', blob);

            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = suggestedFilename || 'downloaded_file';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        } else {
            const blob = await response.blob();
            console.log('Zip Blob:', blob);

            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = suggestedFilename || 'project.zip';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        }

        console.log('Download initiated');
        return 'Download initiated';
    } catch (error) {
        console.error('Error downloading project files:', error);
        throw error;
    }
};

export const sendMessage = async (message, chatId, projectId, currentProject, token) => {
    if (!message || !projectId) {
        throw new Error('Message and project ID are required');
    }

    console.log('sendMessage called with:', { message, chatId, projectId, currentProject });
    const fileIds = extractFileIds(message);
    console.log("fileIds", fileIds);
    const containsNewFile = fileIds.some(fileId => !currentProject.files[fileId]);

    const response = await fetch(`${API_URL}/api/send-message`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
            message,
            chat_id: chatId,
            project_id: projectId,
            contains_new_file: containsNewFile
        }),
    });

    if (!response.ok) {
        const errorData = await response.json();
        // Throw a custom error object with additional information
        throw {
            type: 'SendMessageError',
            message: errorData.message || 'Failed to send message',
            status: response.status
        };
    }

    return response; // Return the response object directly
};

function extractFileIds(message) {
    const regex = /<span[^>]*data-file-id="([^"]*)"[^>]*>/g;
    const fileIds = [];
    let match;
    while ((match = regex.exec(message)) !== null) {
        fileIds.push(match[1]);
    }
    return fileIds;
}

export const fetchChatMessages = async (projectId, token) => {
    console.log('fetchChatMessages called with:', { projectId });
    if (!projectId) {
        throw new Error('Project ID is required for fetching chat messages');
    }
    const response = await fetchWithToken(`${API_URL}/api/chat-messages/${projectId}`, {
        headers: {
            'Content-Type': 'application/json',
        },
    }, token);

    if (!response.ok) {
        throw new Error('Failed to fetch chat messages');
    }

    const result = await response.json();
    console.log("chat data", result.data);

    return result.status === 'success' ? result.data : Promise.reject(result.message);
};

export const fetchDesignObject = async (projectId, token) => {
    console.log('fetchDesignObject called with:', { projectId });
    if (!projectId) {
        throw new Error('Project ID is required for fetching chat messages');
    }
    const response = await fetchWithToken(`${API_URL}/api/design-object/${projectId}`, {
        headers: {
            'Content-Type': 'application/json',
        },
    }, token);

    if (!response.ok) {
        throw new Error('Failed to fetch chat messages');
    }

    const result = await response.json();
    console.log("design data", result.data);

    return result.status === 'success' ? result.data : Promise.reject(result.message);
};

export const uploadFiles = async (files, projectId, chatId, userId, annotate, token) => {
    if (!userId) {
        throw new Error('User ID is required for file upload');
    }

    const formData = new FormData();
    files.forEach((fileData, index) => {
        formData.append(`files`, fileData.file);
        formData.append(`topology_${index}`, fileData.topology);
        formData.append(`parent_file_id_${index}`, fileData.parentFileId || 'null');
    });
    formData.append('project_id', projectId || 'no_project');
    formData.append('chat_id', chatId);
    formData.append('user_id', userId);
    formData.append('annotate', annotate);

    const defaultHeaders = {
        'Authorization': `Bearer ${token}`
    };

    console.log("formData", formData);

    const response = await fetch(`${API_URL}/api/upload-files`, {
        method: 'POST',
        body: formData,
        headers: defaultHeaders,
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();

    if (result.status !== 'success') {
        throw new Error(`Upload failed: ${result.status}`);
    }

    return result.data;
};

export const fetchProjectList = async (userId, token) => {
    try {
        console.log('fetchProjectList called with:', { userId });
        const url = `${API_URL}/api/project-list`;
        const payload = { userId: userId };
        const response = await fetchWithToken(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        }, token);

        console.log("fetchProjectList response", response);

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (data.status === 'success') {
            const projectsData = JSON.parse(data.data);
            if (Array.isArray(projectsData.projects)) {
                const response = projectsData.projects.map(project => ({
                    project_id: project.project_id,
                    user_id: project.user_id,
                    project_name: project.project_name,
                    chat_id: project.chat_id,
                    status: project.status,
                    files: project.files,
                    last_modified: project.last_modified
                }));
                return response;
            } else {
                throw new Error('Unexpected data format: projects is not an array');
            }
        } else {
            throw new Error(data.message || 'Server returned an error');
        }
    } catch (error) {
        console.error('Error in fetchProjectList:', error.message);
        throw error;
    }
};

export const deleteFileFromProject = async (projectId, fileUuid, token) => {
    console.log('deleteFileFromProject called with:', { projectId, fileUuid });
    const response = await fetchWithToken(`${API_URL}/api/delete-file`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ project_id: projectId, file_uuid: fileUuid }),
    }, token);

    if (!response.ok) {
        throw new Error('Failed to delete file');
    }

    const result = await response.json();
    return result.status === 'success' ? result.data : Promise.reject(result.message);
};

export const renameFile = async (fileId, newName, oldName, userId, token) => {
    console.log('renameFile called with:', { fileId, newName, oldName, userId });
    const response = await fetchWithToken(`${API_URL}/api/rename-file`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ file_id: fileId, new_name: newName, old_name: oldName, user_id: userId }),
    }, token);

    if (!response.ok) {
        throw new Error('Failed to rename file');
    }

    const result = await response.json();
    return result.status === 'success' ? result.data : Promise.reject(result.message);
};

export const deleteFilePermanent = async (fileIds, fileNames, userId, token) => {
    console.log('deleteFilePermanent called with:', { fileIds, fileNames, userId, token });

    const results = [];
    for (let i = 0; i < fileIds.length; i++) {
        try {
            const response = await fetchWithToken(`${API_URL}/api/delete-file-permanent`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ file_id: fileIds[i], file_name: fileNames[i], user_id: userId }),
            }, token);

            if (!response.ok) {
                throw new Error(`Failed to delete file ${fileNames[i]} permanently`);
            }

            const result = await response.json();
            if (result.status === 'success') {
                results.push({ fileId: fileIds[i], success: true });
            } else {
                results.push({ fileId: fileIds[i], success: false, message: result.message });
            }
        } catch (error) {
            console.error(`Error deleting file ${fileNames[i]}:`, error);
            results.push({ fileId: fileIds[i], success: false, message: error.message });
        }
    }

    return results;
};

export const getUserInfo = async (user_id, token) => {
    try {
        console.log('getUserInfo called with:', { user_id, token });
        const response = await fetchWithToken(`${API_URL}/api/user-info`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ user_id: user_id }),
        }, token);

        if (!response.ok) {
            throw new Error('Failed to fetch user info');
        }

        const data = await response.json();
        return data.status === 'success' ? data.data : Promise.reject(data.message);
    } catch (error) {
        console.error('Error in getUserInfo:', error.message);
        throw error;
    }
};

export const getUserPreferences = async (userId, token) => {
    try {
        console.log('getUserPreferences called with:', { userId });
        const response = await fetchWithToken(`${API_URL}/api/get-user-preferences`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ user_id: userId }),
        }, token);

        if (!response.ok) {
            console.error('Failed to fetch user preferences. Status:', response.status);
            throw new Error('Failed to fetch user preferences');
        }

        const data = await response.json();
        return data.status === 'success' ? data.data : Promise.reject(data.message);
    } catch (error) {
        console.error('Error in getUserPreferences:', error.message);
        throw error;
    }
};

export const saveUserPreferences = async (preferences, token) => {
    try {
        console.log('saveUserPreferences called with:', { preferences });
        const response = await fetchWithToken(`${API_URL}/api/save-user-preferences`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(preferences),
        }, token);

        if (!response.ok) {
            console.error('Failed to save user preferences. Status:', response.status);
            throw new Error('Failed to save user preferences');
        }

        const data = await response.json();
        return data.status === 'success' ? data.data : Promise.reject(data.message);
    } catch (error) {
        console.error('Error in saveUserPreferences:', error.message);
        throw error;
    }
};

export const addFileToProject = async (fileId, projectId, token) => {
    try {
        console.log('addFileToProject called with:', { fileId, projectId });
        const response = await fetchWithToken(`${API_URL}/api/add-file-to-project`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ file_id: fileId, project_id: projectId }),
        }, token);

        if (!response.ok) {
            console.error('Failed to add file to project. Status:', response.status);
            throw new Error('Failed to add file to project');
        }

        const data = await response.json();
        console.log("Updated Project:", data);
        return data.status === 'success' ? data.data : Promise.reject(data.message);
    } catch (error) {
        console.error('Error in addFileToProject:', error.message);
        throw error;
    }
};

export const fetchArtifact = async (file_id, token) => {
    console.log('fetchArtifact called with:', { file_id });
    const response = await fetchWithToken(`${API_URL}/api/fetch-artifact`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ file_id: file_id }),
    }, token);

    if (!response.ok) {
        throw new Error('Failed to fetch artifact');
    }
    const result = await response.json();
    console.log("type of result", typeof result);
    console.log("fetchArtifact response:", result, "result.status", result["status"]);

    if (result.status === 'success') {
        console.log("Artifact data:", result.data);
        return result.data;
    } else {
        console.error("Error fetching artifact:", result.message);
        throw new Error(result.message || 'Failed to fetch artifact');
    }
};

export const addSampleProject = async (userId, sampleNumber, token) => {
    console.log('addSampleProject called with:', { sampleNumber, userId, token });
    const response = await fetchWithToken(`${API_URL}/api/add-sample-project`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_id: userId, sample_number: sampleNumber }),
    }, token);

    if (!response.ok) {
        throw new Error('Failed to add sample project');
    }

    const data = await response.json();
    return data.status === 'success' ? data.data : Promise.reject(data.message);
}

export const generateInstructions = async (projectId,override, token) => {
    console.log('generateInstructions called with:', { projectId });
    try {
        const response = await fetchWithToken(`${API_URL}/api/generate-instructions`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ project_id: projectId, override: override }),
        }, token);

        if (!response.ok) {
            const errorData = await response.json();
            // Throw a custom error object with additional information
            throw {
                type: 'GenerateInstructionsError',
                message: errorData.message || 'Failed to generate instructions',
                status: response.status
            };
        }

        return response; // Return the response object directly for streaming
    } catch (error) {
        console.error('Error in generateInstructions:', error);
        throw error;
    }
};

export const navigateDesignAPI = async (projectId, designId, direction, token) => {
    console.log('navigateDesignAPI called with:', { projectId, designId, direction });
    const response = await fetchWithToken(`${API_URL}/api/navigate-design`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ project_id: projectId, design_id: designId, direction: direction }),
    }, token);

    if (!response.ok) {
        throw new Error('Failed to navigate design');
    }

    const result = await response.json();
    console.log("navigateDesignAPI response:", result, "result.status", result["status"]);
    return result.status === 'success' ? result.data : Promise.reject(result.message);
};

export const updateFileProperties = async (fileId, updates, userId, token) => {
    console.log('updateFileProperties called with:', { fileId, updates, userId });
    try {
        const response = await fetchWithToken(`${API_URL}/api/update-file-properties`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ file_id: fileId, updates, user_id: userId }),
        }, token);

        if (!response.ok) {
            throw new Error('Failed to update file properties');
        }

        const result = await response.json();
        return result.status === 'success' ? result.data : Promise.reject(result.message);
    } catch (error) {
        console.error('Error in updateFileProperties:', error);
        throw error;
    }
};

export const createCheckoutSession = async (token, userId, quantity) => {
    const response = await fetchWithToken(`${API_URL}/api/create-checkout-session`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user_id: userId, quantity: quantity }),
    }, token);

    if (!response.ok) {
        throw new Error('Failed to create checkout session');
    }

    const result = await response.json();
    return result.status === 'success' ? result.data : Promise.reject(result.message);
};

export const fetchUsageCredits = async (userId, token) => {
    console.log('fetchUsageCredits called with:', { userId });
    try {
        const response = await fetchWithToken(`${API_URL}/api/fetch-usage-credits`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ user_id: userId }),
        }, token);

        if (!response.ok) {
            throw new Error('Failed to fetch usage credits');
        }

        const result = await response.json();
        console.log("fetchUsageCredits result", result);
        return result.status === 'success' ? result.data.credits : Promise.reject(result.message);
    } catch (error) {
        console.error('Error in fetchUsageCredits:', error);
        throw error;
    }
};

export const submitArtifactInputsAPI = async (inputs, projectId, token) => {
  console.log('submitArtifactInputsAPI called with:', { inputs, projectId });
  const response = await fetchWithToken(`${API_URL}/api/submit-artifact-inputs`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ inputs, project_id: projectId }),
  }, token);

  if (!response.ok) {
    throw new Error('Failed to submit artifact inputs');
  }

  const result = await response.json();
  return result.status === 'success' ? result.data : Promise.reject(result.message);
};