import React, { useState, useEffect } from "react";
import "../style/PaymentModal.css";
import { createCheckoutSession } from "../api.js";
import { useSelector, useDispatch } from "react-redux";

const PaymentModal = ({ onClose }) => {
    const token = useSelector(state => state.user.access_token);
    const userId = useSelector(state => state.user.user_id);
    const [message, setMessage] = useState("");
    const [credits, setCredits] = useState("10");
    const currentCredits = useSelector(state => state.user.usageCredits) || 0;
    const dispatch = useDispatch();

    useEffect(() => {
        checkUrlParams();
    }, []);

    const checkUrlParams = () => {
        const searchParams = new URLSearchParams(window.location.search);

        if (searchParams.get("success") === "true") {
            dispatch(setPopupMessage("Payment succeeded! You will receive an email confirmation."));
            removeQueryParams();
            onClose(); // Close the modal
        } else if (searchParams.get("canceled") === "true") {
            dispatch(setPopupMessage("Payment failed or was canceled. Please try again."));
            removeQueryParams();
            onClose(); // Close the modal
        }
    };

    const removeQueryParams = () => {
        const url = new URL(window.location);
        url.searchParams.delete("success");
        url.searchParams.delete("canceled");
        window.history.replaceState({}, document.title, url.pathname);
    };

    const handleInputChange = (e) => {
        setCredits(e.target.value);
    };

    const handleCheckout = async (e) => {
        e.preventDefault();
        const creditValue = parseInt(credits);

        if (isNaN(creditValue) || !Number.isInteger(creditValue)) {
            setMessage("Please enter a whole number between 1 and 100 credits.");
            return;
        }

        if (creditValue < 1) {
            setMessage("You must purchase at least 1 credit.");
            return;
        }

        if (creditValue > 100) {
            setMessage("For purchases over 100 credits, please contact sales@labkick.ai for a bigger discount.");
            return;
        }

        try {
            const session = await createCheckoutSession(token, userId, creditValue);
            window.location.href = session.url;
        } catch (error) {
            console.error("Error creating checkout session:", error);
            setMessage("An error occurred. Please try again.");
        }
    };

    const handleClose = () => {
        removeQueryParams();
        setMessage(""); // Reset message when closing the modal
        onClose();
    };

    const totalPrice = Number.isInteger(Number(credits)) && Number(credits) >= 0 ? Number(credits) * 10 : 0;

    return (
        <div className="checkout-modal-overlay" onClick={handleClose}>
            <div className="checkout-modal-content" onClick={(e) => e.stopPropagation()}>
                <h2 className="checkout-modal-title">Credits</h2>
                <hr className="checkout-modal-divider" />
                <div className="checkout-current-credits-container">
                    <p className="checkout-current-credits">{currentCredits}</p>
                    <span className="checkout-current-credits-label">Current credits</span>
                </div>
                <hr className="checkout-modal-divider" />
                <p className="checkout-subtitle">Purchase credits for DNA assembly plans</p>
                <div className="checkout-input-container">
                    <div className="checkout-input-wrapper">
                        <input
                            type="text"
                            value={credits}
                            onChange={handleInputChange}
                            placeholder="Enter number of credits"
                            className="checkout-credit-input"
                        />
                    </div>
                    <button onClick={handleCheckout} className="checkout-buy-button">
                        Buy
                    </button>
                </div>
                <p className="checkout-total-price">Total: ${totalPrice}</p>
                <p className="checkout-contact-sales">
                    Need more? <a href="mailto:sales@labkick.ai">Contact sales.</a>
                </p>
                {message && <p className="checkout-message">{message}</p>}
            </div>
        </div>
    );
};

export default PaymentModal;
